.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-1 {
    flex: 1;
}

.align-center {
    align-items: center;
}

.flex-start {
    align-items: flex-start;
}

.flex-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.border-box {
    box-sizing: border-box;
}

.full-width {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.full-height {
    height: 100%;
}

.space-between{
    justify-content: space-between;
}

.align-text-center {
    text-align: center;
}

.align-text-left {
    text-align: left;
}

.align-text-right {
    text-align: right;
}

.scrollable {
    overflow-y: auto;
}

.rtl {
    direction: rtl;
    text-align: right;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 600;
}

.overflow-x {
    overflow-x: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.pre-wrap {
    white-space: pre-wrap;
}
