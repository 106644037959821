
// Breakpoints:
$small-desktop-min: 1024px;
$small-desktop-max: 1023px;
$mobile-min: 768px;
$mobile-max: 767px;

$header-color: #5658bf;
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  width: 100%;
  height: 100%;
  text-align: right;
  direction: rtl;
}

h1 {
  color: $header-color;
  font-size: 48px;
  line-height: 56px;
  font-weight: bold;
  @media (max-width: $small-desktop-max) {
    font-size: 40px;
    line-height: 42px;
  }
  @media (max-width: $mobile-max) {
    font-size: 32px;
    line-height: 36px;
  }
}

h2 {
  color: $header-color;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  @media (max-width: $small-desktop-max) {
    font-size: 28px;
    line-height: 32px;
  }
  @media (max-width: $mobile-max) {
    font-size: 24px;
    line-height: 28px;
  }
}

h3 {
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin: 16px 0 24px;
  @media (max-width: $small-desktop-max) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: $mobile-max) {
    font-size: 20px;
    line-height: 24px;
  }
}


/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c2c2c2;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}
